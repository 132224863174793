exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subscribe-success-tsx": () => import("./../../../src/pages/subscribe-success.tsx" /* webpackChunkName: "component---src-pages-subscribe-success-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-markdown-page-template-tsx": () => import("./../../../src/templates/markdownPageTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-page-template-tsx" */),
  "component---src-templates-publication-template-tsx": () => import("./../../../src/templates/publicationTemplate.tsx" /* webpackChunkName: "component---src-templates-publication-template-tsx" */),
  "component---src-templates-publications-template-tsx": () => import("./../../../src/templates/publicationsTemplate.tsx" /* webpackChunkName: "component---src-templates-publications-template-tsx" */)
}

